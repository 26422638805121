import { FC, ReactNode } from 'react';
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '../../components/resizable/resizable';
import { useStoreContext } from '@xspecs/design-system';
import { debounce } from '@xspecs/design-system/src/ui/ux-sketch-components/utils/debounce';

type FileLayoutProps = {
  Left: ReactNode;
  Right?: ReactNode;
};

export const FileLayout: FC<FileLayoutProps> = (props) => {
  const { Left, Right } = props;
  const { store } = useStoreContext();
  const detailsPane = store.use.panels()['details'];
  const setPanels = store.use.setPanels();

  const debouncedSize = debounce((size) => setPanels('details', 'size', size), 300);

  const defaultLeftSize = 70;
  const defaultRightSize = 100 - defaultLeftSize;

  const leftSize = detailsPane?.size ? 100 - detailsPane.size : defaultLeftSize;
  const rightSize = detailsPane?.size ?? defaultRightSize;

  return (
    <ResizablePanelGroup direction="horizontal" className="w-full h-full">
      <ResizablePanel id="left-panel" order={1} defaultSize={Right ? leftSize : 100}>
        {Left}
      </ResizablePanel>
      {Right ? (
        <>
          <ResizableHandle />
          <ResizablePanel id="right-panel" order={2} minSize={15} onResize={debouncedSize} defaultSize={rightSize}>
            {Right}
          </ResizablePanel>
        </>
      ) : null}
    </ResizablePanelGroup>
  );
};
